import React from "react";

import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

import { app } from "../firebase/config";

import { iAction } from "../interfaces/i-action";

type Action = { payload?: { isSignedIn: boolean } | undefined, type: 'SIGN_IN' | 'SIGN_OUT' };
// eslint-disable-next-line no-unused-vars
type Dispatch = (action: Action) => void
type State = boolean | undefined;
type AuthProviderProps = { children: React.ReactNode, isSignedIn?: boolean }

// eslint-disable-next-line no-empty-function
const AuthContext = React.createContext<{ state: State; dispatch: Dispatch }>({ state: false, dispatch: () => { } });

const auth = getAuth(app);

const authReducer = (state: State = false, action: iAction) => {
    const { type } = action;

    switch (type) {
        case 'SIGN_OUT': {
            return false;
        }
        case 'SIGN_IN': {
            return true;
        }
        default: {
            return state;
        }
    }
};

const signInUser = (dispatch: Dispatch, email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            // Signed in 
            dispatch({ type: 'SIGN_IN' });
        })
        .catch((error) => {
            // eslint-disable-next-line no-unused-vars
            const errorCode = error.code;
            // eslint-disable-next-line no-unused-vars
            const errorMessage = error.message;
        });
};

const signOutUser = (dispatch: Dispatch) => {
    return signOut(auth)
        .then(() => {
            // Sign-out successful.
            dispatch({ type: 'SIGN_OUT' });
        }).catch((error) => {
            // An error happened.
            // eslint-disable-next-line no-console
            console.log({ error });
        });
};

const useAuthContext = () => {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuthContext must be used within a AuthProvider')
    }

    return context
}

const AuthProvider = ({ children, isSignedIn }: AuthProviderProps) => {
    const [state, dispatch] = React.useReducer(authReducer, isSignedIn ?? false);

    const value = { state, dispatch };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthProvider, signInUser, signOutUser, useAuthContext }
import { useEffect } from "react";

// if you want to ignore this trigger, decorate the element or its relevant ancestors with the data-no-outside-events attribute
const useOutsideClickHandler = (ref: { current: Element | HTMLElement | null }, callback: Function) => {
    useEffect(() => {
        if (!ref) return;

        /**
         * Alert if clicked on outside of element
         */
        const hasIgnoreDataAttribute = (target: any): boolean => {
            if (!target) return false;
            else if (target.closest('[data-no-outside-events]')) return true;
            else return false;
        };

        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target) && !hasIgnoreDataAttribute(event.target)) {
                if (callback) callback(event);
            }
        }

        // Bind the event listener
        document.addEventListener('click', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    }, [callback, ref]);
};

export default useOutsideClickHandler;
export const formatImgKitUrl = (firebaseUrl: string) => {
    return firebaseUrl.replaceAll(' ', '_').replace('https://firebasestorage.googleapis.com', `https://ik.imagekit.io/${process.env.REACT_APP_IMGKIT_ID}`);
};

export const isMobile = () => {
    return window.matchMedia('(max-width: 1080px)').matches;
};

export const isTouchDevice = () => {
    return (('ontouchstart' in window));
};
import { collection, DocumentData, getDocs, orderBy, query, QueryDocumentSnapshot, QuerySnapshot, where } from 'firebase/firestore';
import React, { useEffect } from 'react';

import { projectFirestore } from '../firebase/config';

import { iAction } from '../interfaces/i-action';
import { iCategory } from '../interfaces/i-category';
import { iImage } from '../interfaces/i-image';

interface iActionPayload {
    categories?: iCategory[] | null,
    images?: iImage[] | null
}

type Action = { payload?: iActionPayload | undefined, type: 'CLEAR_IMAGES' | 'FETCH_CATEGORIES' | 'FETCH_IMAGES' };
// eslint-disable-next-line no-unused-vars
type Dispatch = (action: Action) => void;
type ImagesProviderProps = { children: React.ReactNode };

// eslint-disable-next-line no-empty-function
const ImagesContext = React.createContext<{ state: iActionPayload; dispatch: Dispatch }>({ state: {}, dispatch: () => { } });

const fetchCategories = (dispatch: Dispatch) => {
    const q = query(collection(projectFirestore, 'category'), orderBy('name', 'asc'));

    getDocs(q)
        .then((snap: QuerySnapshot<DocumentData>) => {
            const categories: iCategory[] = [];

            snap.docs.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
                const cat: any = { ...doc.data() };
                cat.id = doc.id;

                categories.push(cat);
            });

            dispatch({ type: 'FETCH_CATEGORIES', payload: { categories } });
        });
};

const fetchImages = (dispatch: Dispatch, categoryId: string) => {
    dispatch({ type: 'CLEAR_IMAGES' });

    const q = query(collection(projectFirestore, 'images'), where('category', '==', categoryId), orderBy('dateCreated', 'desc'));

    getDocs(q)
        .then((snap: QuerySnapshot<DocumentData>) => {
            const images: iImage[] = [];

            snap.docs.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
                const image: any = { ...doc.data() };
                image.id = doc.id;

                images.push(image);
            });

            dispatch({ type: 'FETCH_IMAGES', payload: { images } });
        });
};

const imagesReducer = (state: iActionPayload = {}, action: iAction): iActionPayload => {
    const { payload, type } = action;

    switch (type) {
        case 'CLEAR_IMAGES': {
            return { ...state, ...{ images: null } };
        }
        case 'FETCH_CATEGORIES':
        case 'FETCH_IMAGES': {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
};

const useImagesContext = () => {
    const context = React.useContext(ImagesContext);

    if (context === undefined) {
        throw new Error('useImagesContext must be used within a ImagesProvider')
    }

    return context
}

const ImagesProvider = ({ children }: ImagesProviderProps) => {
    const [state, dispatch] = React.useReducer(imagesReducer, {});

    useEffect(() => {
        // Don't fetch categories if we already have them
        if (!state.categories) fetchCategories(dispatch);
    }, []);

    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch };

    return (
        <ImagesContext.Provider value={value}>
            {children}
        </ImagesContext.Provider>
    )
}

export { ImagesProvider, fetchImages, useImagesContext }
import React, { useReducer, useRef } from "react";
import { Link } from "react-router-dom";

import { useAuthContext } from "../../context/auth-context";

import useOutsideClickHandler from "../../hooks/use-outside-click-handler";

import { iAction } from "../../interfaces/i-action";

import { isMobile } from "../../utilities/general";

interface iMenuLink {
    displayText: string,
    showLoggedIn?: boolean,
    url: string
}

import './navigation.scss';

const Navigation = () => {
    const { state: isLoggedIn } = useAuthContext();

    const [showMenu, toggleShowMenuVisibility] = useReducer((state: boolean, action: iAction): boolean => {
        switch (action?.type) {
            case 'SET_MENU_VISIBILITY':
                return action.payload;
            default:
                return !state;
        }
    }, !isMobile());

    const navigationRef = useRef<HTMLElement | null>(null);

    useOutsideClickHandler(navigationRef, () => closeMobileMenu());

    const menuLinks: iMenuLink[] = [
        {
            displayText: 'Home',
            url: '/'
        },
        {
            displayText: 'Images',
            url: '/gallery'
        },
        // {
        //     displayText: 'Audio',
        //     url: '/library'
        // },
        // {
        //     displayText: 'Field Notes',
        //     url: '/notes'
        // },
        {
            displayText: 'About',
            url: '/about'
        },
        {
            displayText: 'Upload',
            showLoggedIn: true,
            url: '/upload'
        }
    ];

    const closeMobileMenu = () => {
        if (isMobile()) {
            toggleShowMenuVisibility({ type: 'SET_MENU_VISIBILITY', payload: false });
        }
    };

    const renderMenuLinks = () => {
        return menuLinks.map((link: iMenuLink) => {
            const { displayText, showLoggedIn, url } = link;

            if (showLoggedIn !== undefined && showLoggedIn !== isLoggedIn) return <React.Fragment key={url} />;

            return (
                <li className="menu__item" key={url}>
                    <Link to={url} className="hover-underline" onClick={closeMobileMenu}>{displayText}</Link>
                </li>
            );
        });
    };

    return (
        <nav role="navigation" className={showMenu ? 'nav-open' : 'nav-closed'} ref={navigationRef}>
            <div className="menu-controls">
                <div className="flex-image-wrapper">
                    <Link to="/" onClick={closeMobileMenu}>
                        <img src="/images/header-logo.png" alt="Clocks For Seeing Photography" />
                    </Link>
                </div>

                <button
                    type="button"
                    id="toggleMainMenu"
                    className={['btn', 'btn-menu'].join(' ')}
                    onClick={toggleShowMenuVisibility}
                    aria-controls="mainMenu"
                    aria-expanded={showMenu}>
                    <span className="btn-menu__inner"></span>
                    <span className="sr-only">Menu</span>
                </button>
            </div>

            <div id="mainMenu" className={['menu'].join(' ')}>
                <ul
                    role="menu"
                    className="menu__main"
                    id="headerMenu">
                    {renderMenuLinks()}
                </ul>
            </div>
        </nav>
    );
};

export default Navigation;
import React from 'react';

import Navigation from '../navigation/navigation';

const Header = () => {
    return (
        <header style={{ marginBottom: '1rem' }}>
            <Navigation />
        </header>
    );
};

export default Header;
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthProvider } from './context/auth-context';
import { ImagesProvider } from './context/images-context';

import App from './components/app';

const About = lazy(() => import('./pages/about/about'));
const Gallery = lazy(() => import('./pages/gallery/gallery'));
const Home = lazy(() => import('./pages/home/home'));
const Images = lazy(() => import('./pages/images/images'));
const Upload = lazy(() => import('./pages/upload/upload'));
const SignIn = lazy(() => import('./pages/signin/signin'));
const SignOut = lazy(() => import('./pages/signout/signout'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Suspense><Home /></Suspense>} />
            <Route path="/about" element={<Suspense><About /></Suspense>} />
            <Route path="/upload" element={<Suspense><Upload /></Suspense>} />
            <Route path="/gallery" element={<Suspense><ImagesProvider><Gallery /></ImagesProvider></Suspense>} />
            <Route path="/gallery/:galleryId" element={<Suspense><ImagesProvider><Gallery /></ImagesProvider></Suspense>} />
            <Route path="/gallery/:galleryId/:imageId" element={<Suspense><ImagesProvider><Images /></ImagesProvider></Suspense>} />
            <Route path="/images/:pageId" element={<Suspense><Images /></Suspense>}>
              <Route path="/images/:pageId/:imageId" element={<Suspense><Images /></Suspense>} />
            </Route>
            <Route path="/signin" element={<Suspense><SignIn /></Suspense>} />
            <Route path="/signout" element={<Suspense><SignOut /></Suspense>} />
            <Route path="*" element={<Suspense><Home /></Suspense>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
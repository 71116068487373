import React, { Suspense, useLayoutEffect } from 'react';
import ReactGA from "react-ga4";
import { Outlet } from 'react-router-dom';

import './app.scss';

import Header from './header/header';

function App() {
  useLayoutEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA4_TRACKING_ID as string);
  }, []);

  return (
    <div className="app">
      <Header />

      <div className="content-wrapper">
        <main>
          <Suspense fallback="">
            <Outlet />
          </Suspense>
        </main>

        <footer>
          &copy; Eric Lukavsky / 2012 - {new Date().getFullYear()}
        </footer>
      </div>
    </div>
  );
}

export default App;
